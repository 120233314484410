<template>
  <v-container class="fill-height pt-0" fluid>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-alert
          v-model="alert"
          color="#fdf0d7"
          dense
          type="info"
          class="text-center black--text caption mb-0"
          dismissible
        >Conecte a sua equipa num ambiente que priorisa o trabalho em grupo. Cadastre novos usuários e atribua permissões de forma simples e segura.</v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8">
        <div class="text-left subtitle">
          <strong>Utiizadores</strong>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            dense
            outlined
            rounded
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="py-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
      </v-col>
      <v-col cols="12" md="3" class="text-right">
        <v-btn
          rounded
          text
          outlined
          @click.prevent="dialog = !dialog"
        >
          <v-icon
            left
            color="#F7A400"
          >mdi-plus-circle-outline</v-icon>Novo Utilizador
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3" v-for="user in getUsers" :key="user.name">
        <user-card :user="user" @edit="editUser" @delete="openConfirmeDialog" @show="showUser" />
      </v-col>
    </v-row>
    <add :dialog="dialog" :show="show" :user="user" @close="closeAddDialog()" />
    <confirm-dialog 
      :dialog="confirm" 
      :id="removeId" 
      question="Pretende remover o Utilizador?" 
      @yes="deleteUser" 
      @no="confirm = false" 
    />
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-container>
</template>

<script>
import { GET_USERS_ROOT_QUERY } from '../graphql/Query'
import { DELETE_USER_MUTATION } from '../graphql/Mutation'
import { mapActions, mapGetters } from 'vuex'
import { formatError } from '@/utils'
import Add from './../components/AddUserDialog.vue'
import ConfirmDialog from './../../../components/ConfirmDialog.vue'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import UserCard from './../components/UserCard.vue'
import User from '@/models/User'
import dialogMixins from '@/mixins/dialog'
export default {
  name: 'User',
  mixins: [
    dialogMixins
  ],
  components: {
    Add,
    ConfirmDialog,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
    UserCard
  },
  data: () => ({
    alert: true,
    user: new User(),
    users: []
  }),
  apollo: {
    users: {
      query: GET_USERS_ROOT_QUERY
    }
  },
  watch: {
    users: function(val) {
      this.setUsersState(val)
    }
  },
  computed: {
    ...mapGetters({
      getUsers: 'user/getUsers'
    })
  },
  methods: {
    ...mapActions({
      removeUserState: 'user/removeUser',
      setUsersState: 'user/setUsers'
    }),
    closeAddDialog () {
      this.user = new User()
      this.dialog = false;
      this.show = false;
    },
    async deleteUser (id) {
      this.confirm = false
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: DELETE_USER_MUTATION,
          variables: { id }
        })
        this.removeUserState(id)
        this.success = 'Utilizador removido com sucesso!'
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    editUser (user) {
      this.user = user
      this.dialog = true
    },
    openConfirmeDialog (id) {
      this.removeId = id
      this.confirm = true
    },
    showUser (user) {
      this.user = user
      this.show = true
      this.dialog = true
    },
  }
}
</script>